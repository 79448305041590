import { useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import type { CustomFormItem } from "astro-types/components";
import TextInput from "./components/TextInput";
import CustomSelect from "./components/Select";
import CustomCheckboxes from "./components/CustomCheckboxes";
import CustomTextArea from "./components/CustomTextarea";
import CustomRadioInputs from "./components/CustomRadioInputs";
import CustomRangeInput from "./components/CustomRangeInput";
import PhoneInput from "./components/PhoneInput";
import Signature from "./components/Signature/Signature";

interface CustomFormI {
  formData: CustomFormItem[];
  formTitle: string;
  apiKey: string;
  pageTitle: string;
}
function CustomForm({ formData, formTitle, apiKey, pageTitle }: CustomFormI) {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (recaptchaRef.current) {
      recaptchaRef.current.execute();
    }
  };

  const onRecaptchaChange = (token: string | null) => {
    if (token && formRef.current) {
      // Append the token to the form
      const tokenInput = document.createElement("input");
      tokenInput.type = "hidden";
      tokenInput.name = "g-recaptcha-response";
      tokenInput.value = token;
      formRef.current.appendChild(tokenInput);

      formRef.current.submit();
    } else {
      console.error("reCAPTCHA token not received");
    }
  };

  useEffect(() => {
    const currentSearchParams = new URLSearchParams(window.location.search);
    const gclid = currentSearchParams.get("gclid");

    if (!gclid || !formRef.current) return;

    const gclidInput = document.createElement("input");
    gclidInput.type = "hidden";
    gclidInput.name = "gclid";
    gclidInput.value = gclid;
    formRef.current.appendChild(gclidInput);
  }, []);

  return (
    <>
      <form
        className="custom-form"
        action="/api/custom-form"
        method="POST"
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <input name="Form" type="hidden" value={formTitle} />
        {formData.map(
          ({
            id,
            fields: {
              Block,
              Label,
              isRequired,
              Placeholder,
              Value,
              Name,
              RangeStep,
              Info,
              Code,
              blockId,
              isDisabled,
            },
          }) => {
            switch (Block) {
              case "TextInput":
                return (
                  <TextInput
                    key={id}
                    label={Label}
                    isRequired={isRequired}
                    placeholder={Placeholder}
                    initialValue={Value}
                    name={Name}
                    info={Info}
                    disabled={isDisabled}
                    type="text"
                  />
                );
              case "PhoneInput":
                return (
                  <PhoneInput
                    key={id}
                    defaultValue={Value}
                    label={Label}
                    info={Info}
                    name={Name}
                    disabled={isDisabled}
                    placeholder={Placeholder}
                  />
                );
              case "EmailInput":
                return (
                  <TextInput
                    key={id}
                    initialValue={Value}
                    label={Label}
                    info={Info}
                    name={Name}
                    placeholder={Placeholder}
                    isRequired={isRequired}
                    disabled={isDisabled}
                    type="email"
                  />
                );
              case "Select":
                const relatedSelectItems = formData.filter(
                  (blockItem) =>
                    blockItem.fields.Block === "SelectItem" &&
                    blockItem.fields.isActive &&
                    blockItem.fields.blockId === blockId,
                );
                return (
                  <CustomSelect
                    key={id}
                    name={Name}
                    items={relatedSelectItems}
                    label={Label}
                    isRequired={isRequired}
                    placeholder={Placeholder}
                    initialValue={Value}
                    info={Info}
                    disabled={isDisabled}
                  />
                );
              case "SelectItem":
                return null;
              case "Checkbox":
                const relatedCheckboxItems = formData.filter(
                  (blockItem) =>
                    blockItem.fields.Block === "CheckboxItem" &&
                    blockItem.fields.isActive &&
                    blockItem.fields.blockId === blockId,
                );
                return (
                  <CustomCheckboxes
                    key={id}
                    items={relatedCheckboxItems}
                    label={Label}
                    info={Info}
                    code={Code}
                  />
                );
              case "CheckboxItem":
                return null;
              case "Radio":
                const relatedRadioItems = formData.filter(
                  (blockItem) =>
                    blockItem.fields.Block === "RadioItem" &&
                    blockItem.fields.isActive &&
                    blockItem.fields.blockId === blockId,
                );

                return (
                  <CustomRadioInputs
                    items={relatedRadioItems}
                    key={id}
                    label={Label}
                    info={Info}
                    name={Name}
                    disabled={isDisabled}
                    required={isRequired}
                  />
                );
              case "RadioItem":
                return null;
              case "Textarea":
                return (
                  <CustomTextArea
                    key={id}
                    label={Label}
                    name={Name}
                    initialValue={Value || Placeholder}
                    info={Info}
                    disabled={isDisabled}
                    required={isRequired}
                  />
                );
              case "Range":
                return (
                  <CustomRangeInput
                    key={id}
                    label={Label}
                    value={Value}
                    info={Info}
                    name={Name}
                    step={RangeStep}
                    disabled={isDisabled}
                    required={isRequired}
                  />
                );
              case "Subheading":
                return (
                  <h2 className="form-subheading" key={id}>
                    {Label}
                  </h2>
                );
              case "Plain Text":
                return (
                  <div
                    className="form-plain-text"
                    key={id}
                    dangerouslySetInnerHTML={{ __html: Label }}
                  />
                );
              case "Signature":
                return (
                  <Signature key={id} label={Label} info={Info} name={Name} />
                );
              case "Submit (Button)":
                return (
                  <button
                    type="submit"
                    disabled={isDisabled}
                    className="button form-submit-button g-recaptcha"
                    key={id}
                  >
                    {Label}
                  </button>
                );
              default:
                return <p key={id}>Unknown form block {Block}</p>;
            }
          },
        )}
        <ReCAPTCHA
          sitekey={apiKey}
          size="invisible"
          ref={recaptchaRef}
          onChange={onRecaptchaChange}
          badge="inline"
        />
        <input type="hidden" name="pageTitle" value={pageTitle} />
      </form>
    </>
  );
}

export default CustomForm;
